'use client';

import { Button, Card, Header, InnerContent } from '@/components';
import { useRouter } from 'next/navigation';
import React from 'react';

export default function NotFound() {
  const router = useRouter();

  return (
    <div className="relative py-4 xl:py-12">
      <InnerContent>
        <Card>
          <div className="flex flex-col items-center xl:p-32 gap-8 text-center">
            <Header as="h4" variant="secondary">
              404
            </Header>
            <Header as="h1" className="text-center" variant="primary">
              Page not found
            </Header>
            <p>
              Sorry, we couldn&apos;t find the page you&apos;re looking for.
            </p>
            <Button onClick={() => router.push('/')}>Go back home</Button>
          </div>
        </Card>
      </InnerContent>
    </div>
  );
}
